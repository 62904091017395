<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <h1 class="font-medium-5 font-weight-bolder">
      {{ $t('Fulfillment Rules') }}
    </h1>
    <div>
      <error-alert
        :fields="formFields"
        :error="error"
      />
      <skeleton v-if="loading" />
      <b-form v-else>
        <validation-observer
          ref="processUpdateVal"
        >
          <b-row>
            <b-col
              v-for="field in [
                'fullfil_process_meters',
                'fullfil_process_generate_warehouse_schedules',
                'fullfil_process_force_order_fill',
                'fullfil_process_generate_warehouse_schedules_Create_schedule_based_on_time',
                'fullfil_process_multi_site_transfer',
                'fullfil_process_generate_warehouse_schedules_Create_schedule_based_order_dates',
                'fullfil_process_meters_auto_return_missing_lost_on_scan',
                'fullfil_process_prompt_for_serial_id_for_components',
                'fullfil_process_meters_auto_return_missing_lost_on_scan_prompt_and_proceed',
                'fullfil_process_open_transfer_order_detail_view',
                'fullfil_process_meters_allow_return_missing_lost',
                'fullfil_process_allow_deleting_cancelling_filled_lines',
                'fullfil_process_meters_allow_return_missing_lost_prompt_and_proceed',
                'fullfil_process_allow_pack_items_on_after_pick_date',
                'fullfil_process_generate_conflicts_on_all_sites',
                'fullfil_process_allow_select_lot_on_partial_sale_return',
                'fullfil_process_warning_dialog_at_asset_filling',
                'fullfil_process_update_past_pick_date_to_current_date_on_order_open',
              ]"
              :key="field"
              class="some-padding"
              md="6"
            >
              <component
                :is="formFields[field].type"
                v-model="process[field]"
                :field="formFields[field]"
                :name="field"
                :is-editable="isEdit"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
              class="some-padding"
            />
            <b-col
              v-for="field in ['fullfil_process_update_future_pick_date_to_current_on_early_prent']"
              :key="field"
              class="some-padding"
              md="6"
            >
              <component
                :is="formFields[field].type"
                v-model="process[field]"
                :field="formFields[field]"
                :name="field"
                :is-editable="isEdit"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
              class="some-padding"
            />
            <b-col
              v-for="field in ['fullfil_process_allow_to_transfer_assign_sell_items_on_orders']"
              :key="field"
              class="some-padding"
              md="6"
            >
              <component
                :is="formFields[field].type"
                v-model="process[field]"
                :field="formFields[field]"
                :name="field"
                :is-editable="isEdit"
              />
            </b-col>
          </b-row>
          <h1 class="font-medium-5 font-weight-bolder">
            {{ $t('Hardware') }}
          </h1>
          <b-row>
            <b-col>
              <b-row>
                <b-col
                  v-for="field in ['fullfil_process_barcode_scanner_id']"
                  :key="field"
                  class="some-padding"
                  md="11"
                >
                  <component
                    :is="formFields[field].type"
                    v-model="process[field]"
                    :field="formFields[field]"
                    :name="field"
                    :is-editable="isEdit"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-col
                v-for="field in ['fullfil_process_overdue_duration']"
                :key="field"
                class="some-padding"
                md="6"
              >
                <b-row class="align-items-center mb-1">
                  <b-col class="col-md-7">
                    <span>{{ $t('Overdue Duration') }}</span>
                  </b-col>
                  <b-col class="ml-n2">
                    <component
                      :is="formFields[field].type"
                      v-model="process[field]"
                      type="number"
                      :field="formFields[field]"
                      :is-editable="isEdit"
                      :name="field"
                      class="my-0"
                      style="width: 70px"
                    />
                    <!--                    style="width: 49px; height: 45px"-->
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                v-for="field in ['fullfil_process_idl_time_out']"
                :key="field"
                class="some-padding"
                md="12"
              >
                <b-row class="align-items-center">
                  <b-col class="col-md-3">
                    <span>{{ $t('Idle Timeout') }}</span>
                  </b-col>
                  <b-col class="col-md-2 ml-n3 mr-2 ml-1">
                    <component
                      :is="formFields[field].type"
                      v-model="process[field]"
                      type="number"
                      :field="formFields[field]"
                      :is-editable="isEdit"
                      :name="field"
                      class="my-0"
                      style="width: 70px"
                    />
                    <!--                style="width: 49px; margin-left: 5px; height: 45px"-->
                  </b-col>
                  <b-col class="col-md-2 ml-n2">
                    <span class="mx-n2">{{ $t('Minutes') }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-col>
          </b-row>
          <h1 class="font-medium-5 font-weight-bolder">
            {{ $t('Service & Maintenance') }}
          </h1>
          <b-row>
            <b-col>
              <b-col
                v-for="field in ['fullfil_process_validate_qc_service_on_return']"
                :key="field"
                class="some-padding px-0"
                md="12"
              >
                <component
                  :is="formFields[field].type"
                  v-model="process[field]"
                  :field="formFields[field]"
                  :name="field"
                  :is-editable="isEdit"
                />
              </b-col>
              <b-col
                v-for="field in ['fullfil_process_validate_qc_service_on_fill']"
                :key="field"
                class="some-padding px-0"
                md="12"
              >
                <component
                  :is="formFields[field].type"
                  v-model="process[field]"
                  :field="formFields[field]"
                  :name="field"
                  :is-editable="isEdit"
                />
              </b-col>
            </b-col>
            <b-col>
              <label class="contacts-label">{{ $t('Maintenance Schedules affective from:') }}</label>
              <b-col>
                <component
                  :is="formFields[field].type"
                  v-for="field in ['fullfil_process_maintenance_schedules_effective_from']"
                  :key="field"
                  v-model="process[field]"
                  v-bind="getProps(field)"
                  :is-editable="isEdit"
                  @keyup="onKeyUp($event, field)"
                />
              </b-col>
            </b-col>
          </b-row>
          <portal to="body-footer">
            <div class="d-flex mt-2 pb-1 justify-content-between">
              <div>
                <b-button
                  v-if="isFormChanged"
                  class="cancelBtn font-weight-bolder"
                  variant="outline-primary"
                  @click="loader"
                >
                  <feather-icon
                    icon="LCancelIcon"
                    size="16"
                  />
                  {{ $t('Cancel') }}
                </b-button>
                <b-button
                  v-else
                  class="cancelBtn font-weight-bolder"
                  variant="outline-primary"
                  @click="$router.push({ name: 'settings' })"
                >
                  {{ $t('Back to Setting') }}
                </b-button>
              </div>
              <div>
                <b-button
                  variant="success"
                  class="saveBtn font-weight-bolder"
                  :disabled="!isFormChanged"
                  @click="submit"
                >
                  {{ $t('SAVE') }}
                </b-button>
              </div>
            </div>
          </portal>
        </validation-observer>
      </b-form>
    </div>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>

import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import { convertToDate, formatDate } from '@core/utils/filter'
import Skeleton from '@/views/settings/fulfillment/processes/components/Skeleton.vue'
import store from '@/store'
import config from '../processesConfig'

export default {
  name: 'ProcessesForm',
  components: {
    ErrorAlert,
    Skeleton,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      loading: false,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    process() {
      return this.$store.state[this.MODULE_NAME].processForm
    },
    fulfillmentFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].fulfillmentFormClone
    },
    isFormChanged() {
      return this.fulfillmentFormClone !== JSON.stringify(this.process)
    },
  },
  created() {
    this.load()
  },

  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    onKeyUp(e, field) {
      if (!e.length) {
        this.process[field] = ''
      }
    },
    getProps(fieldName) {
      return {
        field: this.formFields[fieldName],
        name: fieldName,
        class: this.formFields[fieldName]?.class,
      }
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    loader() {
      this.$refs.processUpdateVal.reset()
      const smth = JSON.parse(this.fulfillmentFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_PROCESS_FORM`, smth)
    },
    load() {
      this.loading = true
      this.$store.commit(`${this.MODULE_NAME}/SET_PROCESS_FORM`, {})
      this.$store.dispatch(`${this.MODULE_NAME}/getSettings`).then(res => {
        const { data } = res
        const mappedObj = {}
        data.forEach(item => {
          if (item.key !== undefined) {
            // eslint-disable-next-line no-nested-ternary
            mappedObj[item.key] = item.value === 'true' ? true : item.value === 'false' ? false : item.value
          } else if (item.barcode_scanner) {
            mappedObj.barcode_scanner = item.barcode_scanner
          }
        })
        const process = this.mappingFields(Object.keys(this.formFields), mappedObj, {
          fullfil_process_barcode_scanner_id: mappedObj.barcode_scanner,
          fullfil_process_maintenance_schedules_effective_from: new Date(convertToDate(mappedObj.fullfil_process_maintenance_schedules_effective_from)).getTime(),
        })

        this.$store.commit(`${this.MODULE_NAME}/SET_PROCESS_FORM`, process)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_FULFILLMENT_PROCESSES_FORM_CLONE`, process)
      }).finally(() => {
        this.loading = false
      })
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.processUpdateVal.validate().then(success => {
        if (success) {
          this.loading = true
          const ignoredItems = ['fullfil_process_maintenance_schedules_effective_from', 'fullfil_process_barcode_scanner_id']
          const data = this.process

          // eslint-disable-next-line no-restricted-syntax
          for (const dataKey in data) {
            if (!ignoredItems.includes(dataKey)) {
              data[dataKey] = String(data[dataKey])
            }
          }
          const process = this.mappingFields(Object.keys(this.formFields), data, {
            fullfil_process_barcode_scanner_id: data.fullfil_process_barcode_scanner_id?.id,
            fullfil_process_maintenance_schedules_effective_from: formatDate(data.fullfil_process_maintenance_schedules_effective_from),
          })
          this.sendNotification(this, process, `${this.MODULE_NAME}/update`)
            .then(() => {
              this.clear()
              // this.$router.push({ name: 'settings-fulfillment-processes' }).catch(() => {})
            }).catch(err => {
              this.error = err.response?.data
              scrollToError(this, this.$refs.processUpdateVal)
            }).finally(() => {
              this.loading = false
              this.onSubmit = false
            })
        }
      })
    },
    clear() {
      this.load()
      this.$refs.processUpdateVal.reset()
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'process'
    const { formFields, dateOptions } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      formFields,
      dateOptions,
    }
  },
}
</script>

<style scoped>
.some-padding{
  padding-left: 10px;
}
</style>
