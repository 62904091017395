import {
  CHECKBOX_LEF, SELECT_INPUT, FLAT_PICKER, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const formFields = {
    fullfil_process_meters: {
      type: CHECKBOX_LEF,
      label: 'Meters',
    },
    fullfil_process_force_order_fill: {
      type: CHECKBOX_LEF,
      label: 'Force Order Fill',
    },
    fullfil_process_multi_site_transfer: {
      type: CHECKBOX_LEF,
      label: 'Multi Site Transfer',
    },
    fullfil_process_meters_auto_return_missing_lost_on_scan: {
      type: CHECKBOX_LEF,
      label: 'Auto Return Missing/Lost on Scan',
    },
    fullfil_process_meters_auto_return_missing_lost_on_scan_prompt_and_proceed: {
      type: CHECKBOX_LEF,
      label: '-Prompt and proceed',
    },
    fullfil_process_meters_allow_return_missing_lost: {
      type: CHECKBOX_LEF,
      label: 'Allow Return Missing/Lost',
    },
    fullfil_process_meters_allow_return_missing_lost_prompt_and_proceed: {
      type: CHECKBOX_LEF,
      label: '-Prompt and proceed',
    },
    fullfil_process_generate_conflicts_on_all_sites: {
      type: CHECKBOX_LEF,
      label: 'Generate Conflicts On All Sites',
    },
    fullfil_process_warning_dialog_at_asset_filling: {
      type: CHECKBOX_LEF,
      label: 'Warning Dialog at Asset Filling',
    },
    fullfil_process_barcode_scanner_id: {
      type: SELECT_INPUT,
      store: 'fullfilBarcodeScannerList',
      label: 'Barcode Scanner Type',
    },
    fullfil_process_validate_qc_service_on_return: {
      type: CHECKBOX_LEF,
      label: 'Validate QC/Service on Return',
    },
    fullfil_process_validate_qc_service_on_fill: {
      type: CHECKBOX_LEF,
      label: 'Validate QC/Service on Fill',
    },
    fullfil_process_generate_warehouse_schedules: {
      type: CHECKBOX_LEF,
      label: 'Generate Warehouse Schedules',
    },
    fullfil_process_generate_warehouse_schedules_Create_schedule_based_on_time: {
      type: CHECKBOX_LEF,
      label: '-Create Schedule Based On Time',
    },
    fullfil_process_generate_warehouse_schedules_Create_schedule_based_order_dates: {
      type: CHECKBOX_LEF,
      label: '-Create Schedules Based On Order Dates',
    },
    fullfil_process_prompt_for_serial_id_for_components: {
      type: CHECKBOX_LEF,
      label: 'Prompt for Serial ID for Components',
    },
    fullfil_process_open_transfer_order_detail_view: {
      type: CHECKBOX_LEF,
      label: 'Open Transfer Order Detail View',
    },
    fullfil_process_allow_deleting_cancelling_filled_lines: {
      type: CHECKBOX_LEF,
      label: 'Allow Deletinq/Cancelling Filled Lines',
    },
    fullfil_process_allow_pack_items_on_after_pick_date: {
      type: CHECKBOX_LEF,
      label: 'Allow pack Items on/after pick date',
    },
    fullfil_process_allow_select_lot_on_partial_sale_return: {
      type: CHECKBOX_LEF,
      label: 'Auto Select Lot on Partial Sale Return',
    },
    fullfil_process_update_past_pick_date_to_current_date_on_order_open: {
      type: CHECKBOX_LEF,
      label: 'Update Past Pick Date to Current Date on Order open',
    },
    fullfil_process_update_future_pick_date_to_current_on_early_prent: {
      type: CHECKBOX_LEF,
      label: 'Update future Pick Date to Current Date on early Prep',
    },
    fullfil_process_allow_to_transfer_assign_sell_items_on_orders: {
      type: CHECKBOX_LEF,
      label: 'Allow to Transfer Assign Sell Items on Orders',
    },
    fullfil_process_overdue_duration: {
      type: TEXT_INPUT,
    },
    fullfil_process_idl_time_out: {
      type: TEXT_INPUT,
    },
    fullfil_process_maintenance_schedules_effective_from: {
      type: FLAT_PICKER,
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
  }

  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    // hour: 'numeric',
    // minute: 'numeric',
  }

  return {
    formFields,
    dateOptions,
  }
}
