<template>
  <div>
    <b-row>
      <b-col
          v-for="field in 18 "
          :key="field"
          class="some-padding"
          md="6"
          style="margin-bottom: 3px"
      >
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" class="some-padding"/>
      <b-col class="some-padding" md="6" style="margin-bottom: 3px">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
          md="6"
          class="some-padding"
      />
      <b-col class="some-padding" md="6" style="margin-bottom: 20px">
        <b-skeleton type="input" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row>
          <b-col class="some-padding" md="11">
            <b-skeleton type="input" />
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-col class="some-padding" md="6" style="margin-bottom: 3px">
          <b-row class="align-items-center">
            <b-col class="col-md-7">
            </b-col>
            <b-col>
              <b-skeleton type="input" style="width: 49px; margin-left: 30px; height: 45px" class="my-0" />
            </b-col>
          </b-row>
        </b-col>
        <b-col class="some-padding" md="12" style="margin-bottom: 3px">
          <b-row class="align-items-center">
            <b-col class="col-md-3">
            </b-col>
            <b-col class="col-md-2 ml-n3 mr-1">
              <b-skeleton type="input" style="width: 49px; margin-left: 5px; height: 45px" class="my-0" />
            </b-col>
            <b-col class="col-md-2 ml-n2">
            </b-col>
          </b-row>
        </b-col>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-col class="some-padding px-0" md="12" style="margin-bottom: 3px">
          <b-skeleton type="input" />
        </b-col>
        <b-col class="some-padding px-0" md="12">
          <b-skeleton type="input" />
        </b-col>
      </b-col>
      <b-col>
        <b-col class="some-padding" md="4">
          <b-skeleton type="input" />
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
  },
}
</script>
